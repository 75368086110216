

















import { Component } from 'vue-property-decorator';

import BaseSettingsPage from './BaseSettingsPage.vue';
import { Stepper, Step } from '@/components/stepper';
import {
  EmailAddresses,
  EmailMessages,
  EmailAttachments,
} from '@/components/settings';

@Component({
  components: {
    Stepper,
    Step,
    EmailAddresses,
    EmailMessages,
    EmailAttachments,
  },
})
export default class EmailSettingsPage extends BaseSettingsPage {}
