
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Stepper } from '@/components/stepper';

@Component
export default class BaseSettingsPage extends Vue {
  mounted(): void {
    if (this.$route.query['step'] && this.$refs['stepper']) {
      const num = +this.$route.query['step'];
      if (num === 0) {
        return;
      }

      (this.$refs['stepper'] as Stepper).select(num - 1);
    }
  }
}
